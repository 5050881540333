<template>
  <main class="sub">
    <div class="inner job-posting pb0">
      <div class="tit req-type">
        <h1>환전 요청</h1>
        <p class="stit">포인트를 현금으로 환전해서 사용하세요.</p>
      </div>
      <div class="sub-cont">
        <div class="exchange-form-wrap">
          <div class="form-item-wrap">
            <div class="label">나의 포인트</div>
            <span>{{ `${insertCommaToNumber(account.pointBalance)}` }} </span>
          </div>
          <div class="form-item-wrap">
            <div class="label">근무 완료 횟수</div>
            <span>{{ workingCnt }}회</span>
          </div>
          <div class="form-item-wrap">
            <div class="label">환전 대기 포인트</div>
            <span>{{ `${insertCommaToNumber(usedPoint)}` }}</span>
          </div>
          <div class="form-item-wrap">
            <div class="label">환전 가능 포인트</div>
            <span>{{ `${insertCommaToNumber(floorPointBalance)}` }}</span>
          </div>
          <div class="form-item-wrap">
            <div class="label">환전 포인트</div>
            <input
              v-if="!WorkExperience"
              v-model="pointToCash"
              ref="pointToCash"
              type="text"
              class="inp"
              placeholder="현금으로 환전할 포인트"
              @keyup="moneyFormatter"
              @blur="isPointBigOrEqualThanCash"
            />
            <input
              v-else
              type="text"
              class="inp"
              placeholder="1회이상 근무경력이 필요합니다."
              readonly
            />
            <button type="button" class="btn-Appli" @click="totalAmount">
              전액 신청
            </button>
          </div>
          <div class="form-item-wrap">
            <div class="label">은행</div>
            <select ref="bank" class="select" @change="changeBank($event)">
              <option>은행을 선택하세요.</option>
              <option
                v-for="(bank, idx) in bankCodeList"
                :key="`bt_${idx}`"
                :value="bank.code"
                :selected="bank.code == bankCode"
              >
                {{ bank.codeValue }}
              </option>
            </select>
          </div>
          <div class="form-item-wrap">
            <div class="label">예금주</div>
            <input
              type="text"
              class="inp"
              name="userNm"
              v-model="userNm"
              ref="userNm"
            />
            <button
              type="button"
              class="btn-edit"
              v-if="isKakao || isNaver || !validUserNm(this.userNm)"
              :style="usedSt"
              @click="routePush(usedRoute)"
            >
              {{ usedText }}
            </button>
          </div>
          <div class="form-item-wrap">
            <div class="label">계좌번호</div>
            <input
              type="text"
              class="inp"
              name="actNumber"
              v-model="actNumber"
              ref="actNumber"
              placeholder="계좌번호를 입력해주세요."
            />
          </div>
          <div class="myp-buy-wrap type01 exchange">
            <ul class="nlist">
              <li class="title">
                <h2>개인 이용약관 제 24 조 (“포인트” 서비스)</h2>
                <a href="javascript:void(0)" @click="termsParam">자세히 보기</a>
              </li>
              <li>
                포인트는 현금처럼 사용할 수 있습니다.<br />(단, 일자리매칭
                서비스를 통해 1회이상 근무경력이 있고 포인트가 3만점 이상이어야
                합니다.)
              </li>
              <li>
                부여된 포인트는 유효기간이 지나면 자동 소멸됩니다.<br
                  class="m"
                /><a href="javascript:void(0)" @click="pointParam"
                  >유효기간 확인</a
                >
              </li>
              <li>
                부정한 목적이나 용도로 포인트를 사용 할 경우 당사에 의해
                포인트가 소멸이 될 수 있습니다.
              </li>
              <li>
                회원탈퇴 및 회원자격 상실(당사에 의해 회원 자격 상실, 사망)에
                의해 포인트는 자동 소멸 됩니다.
              </li>
              <li>포인트는 가족이나 타인에게 상속 또는 양도 되지 않습니다.</li>
              <li class="title">
                <h2>환전 유의사항</h2>
              </li>
              <li>예금주와 회원명이 같아야 환전이 가능합니다.</li>
              <li>출금액은 30,000원 이상 10,000원 단위로 가능합니다.</li>
              <li>환전 요청 후 평일 기준 은행 영업일 익일 환전됩니다.</li>
              <li>주말이나 공휴일은 이용에 제한이 있을 수 있습니다.</li>
            </ul>
          </div>
        </div>
        <br />
      </div>
    </div>
    <div class="myp-btn-wrap">
      <button
        v-if="workingCnt !== 0"
        type="button"
        class="btn-save01"
        @click="exchange"
      >
        환전요청
      </button>
      <button type="button" class="btn-cancel" @click="goBack">취소</button>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  exchangePointToCash,
  getBasicUserInfo,
  getBankCodeList,
  getAccountNum,
} from 'Api/modules'
export default {
  data() {
    return {
      pointToCash: '',
      workingCnt: '',
      usedPoint: '',
      userNm: '',
      bankName: '',
      actNumber: '',
      isKakao: '',
      isNaver: '',
      bankCode: '',
      bankCodeList: [],
    }
  },
  computed: {
    ...mapGetters(['account']),
    floorPointBalance() {
      var calculated =
        Number(this.account.pointBalance) - Number(this.usedPoint)
      if (calculated < 30000) {
        return 0
      }
      return Math.floor(calculated / 10000) * 10000
    },
    WorkExperience() {
      if (this.workingCnt === 0) {
        return true
      } else {
        return false
      }
    },
    usedText() {
      if (!this.validUserNm(this.account.userNm)) {
        return '이름변경'
      } else if (!this.validUserNm(this.userNm)) {
        return '예금주 변경'
      }
      return '변경'
    },
    usedRoute() {
      if (!this.validUserNm(this.account.userNm)) {
        return '/account'
      } else if (!this.validUserNm(this.userNm)) {
        return '/account/bank'
      }
      return '/account'
    },
    usedSt() {
      if (!this.validUserNm(this.account.userNm)) {
        return 'width:60px;'
      } else if (!this.validUserNm(this.userNm)) {
        return 'width:100px;'
      }
      return 'width:60px;'
    },
  },

  watch: {
    actNumber() {
      this.actNumber = this.actNumber.replace(/[^0123456789-]/g, '')
    },
  },
  methods: {
    validUserNm(data) {
      return /^[가-힣]{2,5}$/.test(data)
    },
    totalAmount() {
      this.pointToCash = this.insertCommaToNumber(this.floorPointBalance)
    },
    termsParam() {
      this.$router.push({
        name: 'terms',
        params: {
          backRoute: 'Exchange',
          viewName: 'personal',
        },
      })
    },
    pointParam() {
      this.$router.push({
        name: 'mypagePointHistory',
        params: {
          backRoute: 'Exchange',
        },
      })
    },
    changeBank(event) {
      this.bankCode = event.target.value
      this.bankName =
        event.target.options[event.target.options.selectedIndex].text
      console.log(this.bankCode)
      console.log(this.bankName)
    },
    // actNumberHipen() {
    //   return (this.actNumber = this.actNumber.replace(/[^0-9]/g, ''))
    // },
    async findBankCodeList() {
      this.bankCodeList = await getBankCodeList()
    },
    async isPointBigOrEqualThanCash() {
      const targetNum = this.pointToCash.split(',').join('')
      if (Number(this.floorPointBalance) < Number(targetNum)) {
        this.$toasted.error(
          '환전할 포인트는 환전 가능 포인트보다 클 수 없습니다. 다시 입력하세요.',
        )
        this.pointToCash = ''
      } else if (Number(targetNum) < 30000) {
        this.$toasted.error('환전할 포인트는 30,000원 이상부터 가능합니다.')
        this.pointToCash = ''
      } else if (targetNum.substr(-4) !== '0000') {
        this.pointToCash = this.insertCommaToNumber(this.floorPointBalance)
        this.$toasted.error('환전 요청은 10,000원 단위로 가능합니다.')
      }
    },
    moneyFormatter() {
      this.pointToCash = this.insertCommaToNumber(this.pointToCash)
    },
    async exchange() {
      if (!this.pointToCash || this.pointToCash == '0') {
        this.$toasted.error('환전할 포인트를 입력하세요')
        this.pointToCash = ''
        this.$refs['pointToCash'].focus()
        return false
      }
      if (!this.bankCode) {
        this.$toasted.error('은행을 선택하세요.')
        return false
      }
      if (!this.userNm) {
        this.$toasted.error('예금주를 입력해주세요.')
        return false
      }
      if (!this.validUserNm(this.userNm)) {
        this.$toasted.error('이름 형식이 올바르지 않습니다.')
        return false
      }
      if (this.userNm !== this.account.userNm) {
        this.$toasted.error('회원이름과 예금주가 다릅니다')
        return false
      }
      if (!this.actNumber) {
        this.$toasted.error('계좌번호를 입력해주세요')
        return false
      }
      const res = await exchangePointToCash({
        pointAmt: this.pointToCash.split(',').join(''),
        userNm: this.userNm,
        actNumber: this.actNumber,
        bankName: this.bankName,
        bankCode: this.bankCode,
      })
      if (res.status == 0) {
        this.$toasted.show(this.pointToCash + '포인트가 전환되었습니다.')
        setTimeout(() => {
          this.$router.go()
        }, 300)
      } else {
        this.$toasted.error(
          '포인트 전환 중 에러가 발생했습니다. 다시 시도해주세요.',
        )
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    async fetchPersonalInfo() {
      // const res = await getBasicUserInfo()
      // this.userNm = res.userNm
      // this.bankName = res.bankName !== '' ? res.bankName : ''
      // this.actNumber = res.actNumber !== '' ? res.actNumber : ''
      const res = await getAccountNum({ userId: this.account.userId })
      if (res) {
        this.userNm = res.actHolder
        this.bankCode = res.bankCode
        this.bankName = res.bankName
        this.actHolder = res.actHolder
        this.actNumber = res.actNumber
      }
    },
  },
  async created() {
    const res = await getBasicUserInfo()
    if (res) {
      this.workingCnt = res.workingCnt
      this.usedPoint = res.usedPoint
    }
    await this.fetchPersonalInfo()
    await this.findBankCodeList()
    this.isKakao = this.account.joinType === 'KAKAO' ? true : false
    this.isNaver = this.account.joinType === 'NAVER' ? true : false
    console.log('account', this.account.userNm)
    console.log('userNm', this.userNm)
  },
}
</script>

<style></style>
